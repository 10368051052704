<template>
  <de-dialog v-model:visible="model" class="md:tw-w-12000" content-class="tw-relative tw-h-full">
    <template v-if="!isSent" #header="{ props }">
      <h1 :class="props.class">{{ t('mainPage.contactUs.title') }}</h1>
      <p class="body-b1 tw-text-primary-200 tw-mt-4">{{ t('mainPage.contactUs.subtitle') }}</p>
      <a :href="`mailto:${DE_EMAIL}`" class="tw-inline-block body-b1 tw-mt-4">
        <span class="tw-text-primary-200">{{ t('form.email') }}:</span>
        {{ DE_EMAIL }}
      </a>
    </template>

    <form v-if="!isSent" @submit.prevent="onSubmit">
      <de-form-group>
        <de-form-input-text
          id="name"
          :placeholder="t('form.enterYourX', { field: 'form.name' })"
          :label="t('form.name')"
          :maxlength="DISPLAY_NAME_MAX_LENGTH"
          class="tw-w-full"
        />

        <de-form-input-text
          id="email"
          :placeholder="t('form.enterYourX', { field: 'form.emailAddress' })"
          type="email"
          :maxlength="EMAIL_FIELD_LENGTH"
          :label="t('form.email')"
          class="tw-w-full"
        />

        <de-form-textarea
          id="message"
          rows="5"
          resize="none"
          :label="t('form.message')"
          :placeholder="t('form.enterYourX', { field: 'form.message' })"
          :maxlength="MESSAGE_FIELD_LENGTH"
          class="tw-w-full"
        />
      </de-form-group>

      <de-recaptcha @verify-callback="onVerifyCallback" />

      <de-button
        type="submit"
        :variant="ButtonVariantOptions.confirm"
        :size="ButtonSizeOptions.medium"
        :label="t('common.buttons.send')"
        :loading="isSubmitting"
        :disabled="isSubmitDisabled"
        class="tw-w-full tw-mt-10"
        @click="onSubmit"
      />
    </form>
    <div
      v-else
      class="tw-flex tw-flex-col tw-gap-y-7.5 tw-items-center max-md:tw-absolute tw-left-0 tw-w-full tw-top-1/2 max-md:tw--translate-y-2/3 max-md:tw-px-4"
    >
      <div
        class="tw-bg-primary-600 tw-w-16 tw-h-16 tw-rounded-full tw-flex tw-items-center tw-justify-center"
      >
        <de-svg-icon name="check-circle" class="tw-w-8 tw-h-8 tw-text-accent-500" />
      </div>
      <div class="tw-text-center">
        <div class="heading-h2 tw-mb-4">{{ t('mainPage.contactUs.sendSuccessfully.title') }}</div>
        <p class="body-b1 tw-text-primary-200">
          {{ t('mainPage.contactUs.sendSuccessfully.text') }}
        </p>
      </div>
      <de-button
        :variant="ButtonVariantOptions.confirm"
        :size="ButtonSizeOptions.medium"
        :label="t('common.buttons.gotIt')"
        class="tw-min-w-2500"
        @click="model = false"
      />
    </div>
  </de-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useNuxtApp } from '#app';
import { ref } from 'vue';
import DeDialog from '~/shared/components/lib/dialog/DeDialog.vue';
import DeFormGroup from '~/shared/components/lib/form/form-group/DeFormGroup.vue';
import { DISPLAY_NAME_MAX_LENGTH } from '~/features/user/settings/profile/user-settings-profile.constant';
import DeFormInputText from '~/shared/components/lib/form/form-input/DeFormInputText.vue';
import DeFormTextarea from '~/shared/components/lib/form/form-textarea/DeFormTextarea.vue';
import DeRecaptcha from '~/shared/components/DeRecaptcha.vue';
import { useRecaptcha } from '~/shared/composable/useRecaptcha';
import { computed, isEmpty } from '#imports';
import {
  ButtonSizeOptions,
  ButtonVariantOptions,
} from '~/shared/components/lib/button/button.entity';
import DeButton from '~/shared/components/lib/button/DeButton.vue';
import { useUserStore } from '~/store/user';
import type { ContactPayload } from '~/data/services/contact.service';
import { ContactService } from '~/data/services/contact.service';
import DeSvgIcon from '~/shared/components/lib/svg-icon/DeSvgIcon.vue';
import { DE_EMAIL } from '~/data/domain/constants';

const EMAIL_FIELD_LENGTH = 255;
const MESSAGE_FIELD_LENGTH = 4999;

const model = defineModel<boolean>({ required: true });

const { t } = useI18n();
const userStore = useUserStore();

const { handleSubmit, errors, values, isSubmitting } = useForm({
  validationSchema: object({
    name: string().required(t('form.enterYourX', { field: 'form.name' })),
    message: string().required(t('form.enterYourX', { field: 'form.message' })),
    email: string()
      .required(t('form.enterYourX', { field: 'form.emailAddress' }))
      .email(),
  }),
  initialValues: {
    email: userStore.user.email || '',
    message: '',
    name: '',
  },
});

const { captchaResponseToken, onVerifyCallback } = useRecaptcha();

const isSubmitDisabled = computed(() => {
  return (
    !values.email ||
    !values.message ||
    !values.name ||
    !isEmpty(errors.value) ||
    !captchaResponseToken.value
  );
});

const { $customFetch } = useNuxtApp();
const contactRepository = ContactService($customFetch);
const isSent = ref(false);

const onSubmit = handleSubmit(async (values) => {
  const payload: ContactPayload = {
    ...values,
    'g-recaptcha-response': window.grecaptcha?.getResponse() as string,
  };

  await contactRepository.sendMessage(payload);
  isSent.value = true;
});
</script>
