import type { CustomFetch } from '~/shared/types/custom-fetch.type';

export interface ContactPayload {
  name: string;
  email: string;
  message: string;
  'g-recaptcha-response': string;
}

export const ContactService = (fetch: CustomFetch) => ({
  async sendMessage(payload: ContactPayload): Promise<void> {
    return await fetch('api/contact/send-message', { method: 'POST', body: payload });
  },
});
